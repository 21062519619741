import formOptions from "./formOptions"

export default {
  fields: {
    earClinicDetails: {
      bookingId: {
        type: "text",
        label: "Booking ID",
        value: null,
        disabled: true
      },
      locationId: {
        type: "entity-select",
        props: {
          entity: "Location",
          filterable: true,
          clearable: true,

        },
        label: "Select Location",
        value: null
      },
      patientId: {
        type: "entity-select",
        props(Form) {
          return {
            entity: "Patient",
            filterable: true,
            clearable: true,
            preFilter: { filterBy: "locationId", id: Form.formData.earClinicDetails.locationId.value },
            dependency: { field: "locationId", value: "*" }
          }
        },
        label: "Select Patient",
        value: null
      }
    },
    otoscopy: {
      _options: {
        formatting: "sentenceCase"
      },
      otoscopyLeft: {
        type: "select",
        options: formOptions.otoscopy,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "Otoscopy (Left)",
        value: null
      },
      otoscopyRight: {
        type: "select",
        options: formOptions.otoscopy,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "Otoscopy (Right)",
        value: null
      },
      otoscopyImageLeft: {
        type: "multi-image-upload",
        options: [
          {label: "Otoscopy Image (Left)", description: "Upload Otoscopy Image (Left)", value: "otoscopy_image_left"},
        ],
        value: true,
      },
      otoscopyImageRight: {
        type: "multi-image-upload",
        options: [
          {label: "Otoscopy Image (Right)", description: "Upload Otoscopy Image (Right)", value: "otoscopy_image_right"},
        ],
        value: true,
      },
    },
    middleEarAssessment: {
      _options: {
        formatting: "sentenceCase"
      },
      middleEarAssessmentLeft: {
        type: "select",
        options: formOptions.middleEarAssessment,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "Tympanometry (Left)",
        value: null
      },
      middleEarAssessmentRight: {
        type: "select",
        options: formOptions.middleEarAssessment,
        hideLabel: true,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "Tympanometry (Right)",
        value: null
      },
      acousticReflexesPresentLeft: {
        type: "checkbox",
        value: true,
        label: "Acoustic Reflexes present (Left)",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
      },
      acousticReflexesPresentRight: {
        type: "checkbox",
        value: true,
        label: "Acoustic Reflexes present (Right)",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
      },
    },
    audiometry: {
      _options: {
        formatting: "sentenceCase"
      },
      hearingScreenLeft: {
        type: "select",
        options: formOptions.hearingScreen,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "AC Hearing threshold (Left)",
        value: null
      },
      hearingScreenRight: {
        type: "select",
        options: formOptions.hearingScreen,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "AC Hearing threshold (Right)",
        value: null
      },
      bcHearingScreenLeft: {
        type: "select",
        options: formOptions.hearingScreen,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "BC Hearing threshold (Left)",
        value: null
      },
      bcHearingScreenRight: {
        type: "select",
        options: formOptions.hearingScreen,
        hideLabel: false,
        props: {
          filterable: true,
          clearable: true,
        },
        label: "BC Hearing threshold (Right)",
        value: null
      },
      airBoneGapLeft: {
        type: "switch",
        value: true,
        label: "Air Bone Gap (Left)",
        props: {
          trueLabel: "1",
          falseLabel: "0"
        },
      },
      airBoneGapRight: {
        type: "switch",
        value: true,
        label: "Air Bone Gap (Right)",
        props: {
          trueLabel: "1",
          falseLabel: "0"
        },
      },
      ptaAudiogramImage: {
        type: "multi-image-upload",
        options: [
          {label: "Upload PTA Audiogram Image", description: "Upload PTA Audiogram Image", value: "pta_audiogram"},
        ],
        value: true,
      },
      speechAudiogramImage: {
        type: "multi-image-upload",
        options: [
          {
            label: "Upload Speech Audiogram Image",
            description: "Upload Speech Audiogram Image",
            value: "speech_audiogram"
          },
        ],
        value: true,
      },
    },
    otherCochlearTests: {
      weberRinneTestTable: {
        width: 24,
        type: 'weber-rinne-test-table',
      }
    },
    actions: {
      _options: {
        formatting: "sentenceCase"
      },
      actionTakenLeft: {
        type: "select",
        options: formOptions.actionTaken,
        props: {
          multiple: true,
          hideLabel: true,
          hideValue: true,
          filterable: true,
          clearable: true
        },
        label: "Action Taken (Left)",
        value: null
      },
      actionTakenRight: {
        type: "select",
        props: {
          multiple: true,
          hideLabel: true,
          hideValue: true,
          filterable: true,
          clearable: true
        },
        options: formOptions.actionTaken,
        label: "Action Taken (Right)",
        value: null
      },
      cerumenolyticsAdministeredLeft: {
        type: "checkbox",
        value: true,
        label: "Cerumenolytics administered (Left)",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
      },
      cerumenolyticsAdministeredRight: {
        type: "checkbox",
        value: true,
        label: "Cerumenolytics administered (Right)",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
      },
      recommendTVHeadPhones: {
        type: "checkbox",
        label: "Recommend TV Headphones",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
        value: null
      },
      recommendALD: {
        type: "checkbox",
        label: "Recommend ALD",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
        value: null
      },
      recommendation: {
        type: "select",
        options: formOptions.recommendation,
        hideLabel: false,
        filterable: true,
        label: "Recommendations",
        value: null
      },
      extraNotes: {
        type: "textarea",
        options: [],
        hideLabel: false,
        filterable: true,
        label: "Notes",
        value: null,
        placeholder: "Any extra notes"
      },
      epcClaimed: {
        type: "checkbox",
        label: "EPC Claimed?",
        props: {
          trueLabel: "1",
          falseLabel: "0",
          border: true,
          size: "medium",
        },
        value: null
      },
      clinician: {
        label: "Clinician",
        type: "textarea",
        value: null,
        initialValue: "",
        props: {
          disabled: true
        }
      },
      medicareItems: {
        label: "Medicare Items",
        type: "medicare-items",
        width: 24,
      }
    }
  }
}
