<template>
  <div class="map-container">
    <div class="filter-panel-wrapper">
      <label>Filter by State</label>
      <el-select
          v-model="selectedState"
          size="mini"
          filterable
          placeholder="Select">
        <el-option
            v-for="item in stateSelector"
            :key="item"
            :label="item"
            :value="item" />
      </el-select>
    </div>
    
    <template v-if="locationsLoaded">
      <CoreMap :locations="locations" />
    </template>
  </div>
</template>

<script>
  import {Location} from "@/Modules/OdysseyModels"
  import Geocoder from "@pderas/vue2-geocoder"
  import Vue from "vue"
  Vue.use(Geocoder, {
    defaultCountryCode: null, // e.g. 'CA'
    defaultLanguage:    null, // e.g. 'en'
    defaultMode:        'address', // or 'lat-lng'
    googleMapsApiKey:   'AIzaSyAh0Tj-cOFdOCaBWJgcdfB7Z4sZFQBV5mQ'
  })

  export default {
    name: "LocationsMapView",
    data() {
      return {
        geocoder: null,
        locations: {},
        locationsLoaded: false,
        selectedState: 'QLD',
        stateSelector: [
            'QLD',
            'NSW'
        ]
      }
    },
    watch: {
      async selectedState() {
        await this.fetchLocations()
      }
    },
    async mounted() {
      await Location.api().fetchAll()
      await this.fetchLocations()
    },
    methods: {
      async fetchLocations() {
        const locations = Location.query().where('state', this.selectedState).get()

        this.locations = await Promise.all(locations.map(async (location) => {

          if (location.street && location.city) {

            const addressObject = {
              title: location.title,
              address_line_1: location.street,
              address_line_2: '',
              city: location.city,
              state: location.state,
              zip_code: location.zip,
              country: location.country
            }

            return await this.getLocationCoordinates(addressObject)
          }
          return null
        }))
      },
      async getLocationCoordinates(addressObject) {

        const locationData = {
          coordinates: null,
          title: null
        }

        await Vue.$geocoder.send(addressObject, response => {
          locationData.coordinates = response.results[0].geometry.location
          locationData.infoText = `${addressObject.title} | ${response.results[0].formatted_address}`
        })

        this.locationsLoaded = true
        return locationData
      }
    }
  }
</script>

<style lang="scss" scoped>
.filter-panel-wrapper {
  box-shadow: var(--box-shadow-container);
  border-radius: var(--border-radius-xs);
  padding: 1em;
  margin-bottom: 1em;

  label {
    margin-right: 1em;
  }
}
</style>