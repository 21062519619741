<template>
  <div
      :key="selectedDateFormatted"
      class="staff-view-container">
    <div class="day-header">
      <div class="date">
        {{ dayName }}
        <strong><span class="tall">{{ selectedDateFormatted }}</span></strong> {{ monthName }}
      </div>
      <div class="day">
        <el-button
            size="small"
            @click="changeDay('back')">
          -
        </el-button>
        <strong>{{ selectedDateFormatted }} {{ monthName }}</strong>
        <el-button
            size="small"
            @click="changeDay()">
          +
        </el-button>
      </div>
    </div>

    <div v-if="isLoading">
      Loading ...
    </div>
    <div
        v-else
        class="grid-container">
      <div
          v-for="(staff, staffIndex) in staffMembers"
          :key="`staff-${staffIndex}`"
          class="staff">
        <div class="header">
          {{ staff.firstName }}
          <div class="day-options">
            <el-button
                size="mini"
                @click="createBooking(staff.id)">
              <i class="el-icon-plus" />
            </el-button>
          </div>
        </div>
        <div class="booking-container">
          <template v-for="(booking, bookingIndex) in getBookingsPerStaffMember(staff.id)">
            <div
                :key="`booking-${bookingIndex}`"
                class="booking">
              <BookingDetails
                  @editClientBooking="editClientBooking"
                  @refreshBookings="handleRefreshBookings"
                  :booking="booking" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Booking, Client, Patient, Settings, User} from "@/Modules/OdysseyModels"
  import BookingDetails from "@/Modules/BookingManagement/components/BookingDetails"
  import moment from "moment"

  export default {
    name: "DayView",
    components: {
      BookingDetails
    },
    data() {
      return {
        selectedDate: new Date(),
        currentDate: new Date(),
        bookings: null,
        staffMembers: null,
        settings: null
      }
    },
    computed: {
      isLoading: {
        get() {
          if (this.bookings && this.settings && this.staffMembers) {
            return false
          }
          return true
        },
        set(val) {
            return val
        }
      },
      monthName() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ]
        const d = this.selectedDate
        return `${monthNames[d.getMonth()]} ${d.getFullYear()}`
      },
      selectedDateFormatted() {
        return this.selectedDate.getDate()
      },
      dateFormattedSessions() {
        if (this.bookings) {
          const bookings = JSON.parse(JSON.stringify(this.bookings))
          let startTime = moment().toDate();  // This will return a copy of the Date that the moment uses

          startTime.setHours(9);
          startTime.setMinutes(0);
          startTime.setSeconds(0);
          startTime.setMilliseconds(0);

          let endTime = moment().toDate();  // This will return a copy of the Date that the moment uses

          endTime.setHours(10);
          endTime.setMinutes(0);
          endTime.setSeconds(0);
          endTime.setMilliseconds(0);


          bookings.map((booking, index) => {
            if (!booking.startTime) {

              let newStartTime = new Date(startTime.setHours(startTime.getHours() + index))
              booking.startTime = `${newStartTime.getHours()}:${newStartTime.getMinutes()}`
            }
            if (!booking.endTime) {
              let newEndTime = new Date(endTime.setHours(endTime.getHours() + index))
              booking.endTime = `${newEndTime.getHours()}:${newEndTime.getMinutes()}`
            }
            booking.formattedStartTime = booking.startTime.substring(0, 2)
            booking.marginTop = booking.startTime.substring(3, 5)
          })
          return bookings
        }
         return null
      },
      hours() {
        if (this.settings) {
          const startingHours = this.settings.startingTime.substring(0, 2)
          const finishingHours = this.settings.finishingTime.substring(0, 2)
          const hours = []
          for (let i = startingHours; i <= finishingHours; i++) {
            hours.push(i)
          }
          return hours
        }
        return null
      },
      dayName() {
        const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return names[this.selectedDate.getDay()]
      },
      reloadBookings() {
        return this.$store.state.BookingManagement.reloadBookings
      }
    },
    watch: {
      reloadBookings() {
        this.getBookings()
      },
      selectedDate() {
        this.isLoading = true
        this.getSettings()
        this.getStaff()
        this.getBookings()
      }
    },
    created() {
      this.getSettings()
      this.getStaff()
      this.getBookings()
    },
    methods: {
      handleRefreshBookings() {
        this.getBookings();
      },
      async getSettings() {
        await Settings.api().fetchById()
        this.settings = Settings.find(1)
      },
      async getStaff() {
        await User.api().fetchAll()
        this.staffMembers = User.all()
      },
      async getBookings() {
        const results = await Booking.api().fetchByDate(this.createSQLDate(this.selectedDate))
        this.bookings = results.response.data.data.data
      },
      createSQLDate(dateString) {
        const date = new Date(dateString)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
      },
      addAppointmentNotes(booking) {
        this.$emit("addAppointmentNotes", booking)
      },
      createBooking(staffId) {
        this.$emit("createBooking", this.selectedDate, staffId)
      },
      editClientBooking(booking) {
        new Booking().Actions.openEditBookingDialog(booking)
      },
      calculatedWidth(bookings) {
        const percentage = 100 / bookings.length
        return `width: ${percentage}%`
      },
      changeDay(direction = "forward") {
        if (direction === "forward") {
          this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 1))
        } else {
          this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 1))
        }
        this.$store.dispatch("BookingManagement/updateCurrentDate", this.selectedDate)
       },
      formatDate(date) {
        const dateString = new Date(date)
        const day = dateString.getDate()
        const month = dateString.getMonth() + 1
        const year = dateString.getFullYear()
        return `${day}/${month}/${year}`
      },
      getBookingsPerStaffMember(staffMemberId) {
        const findDate = this.formatDate(this.selectedDate)
        let foundBookings = []
        this.dateFormattedSessions.forEach(item => {
          item.dateFormatted = this.formatDate(item.date)
          // console.log("item dateFormatted", item.dateFormatted)
          // console.log("item.formattedStartTime", item.formattedStartTime)

          if (item.dateFormatted === findDate
              && parseInt(item.selectedUserId) === parseInt(staffMemberId)) {

            if (item.clientId) {
              item.client = Client.find(item.clientId)
            }
            if (item.patientId) {
              item.patient = Patient.find(item.patientId)
              item.client = Patient.find(item.patientId)
            }
            foundBookings.push(item)
          }
        })
        foundBookings.sort((a, b) => (parseInt(a.startTime) > parseInt(b.startTime)) ? 1 : -1)

        return foundBookings
      }
    }
  }
</script>

<style lang="scss" scoped>
  .staff-view-container {
    overflow-x: auto;
    max-width: 100%;

    .day-header {
      padding: 1em 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        flex: 1;
        .tall {
          font-size: 1.2em;
        }
      }
      .day {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        strong {
          display: inline-block;
          margin: 0 1em;
        }

      }
    }

    .grid-container {
      display: flex;
      padding: 0;
      margin: 1em 0 1em 3em;

      .staff {
        flex: 1;
        justify-content: center;
        min-width: 15em;
        border-right: 1px solid #EBEEF5;
        position: relative;

        .day-options {
          position: absolute;
          right: 0.5em;
          top: 0.5em;
        }



        &:first-child {
          border-left: 1px solid #EBEEF5;
        }


        .header {
          border-top: 1px solid #EBEEF5;
          padding: 1em 0;
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid #EBEEF5;
        }

        .booking-container {

          width: 100%;
          .booking {
            width: 100%;
            margin: 1px;
            &:hover {
              z-index: 1000;
            }
          }
        }

        .hour {
          border-bottom: 1px solid #EBEEF5;
          height: 60px;
          position: relative;
          .time {
            top: -1.85em;
            left: -3em;
            padding-right: 0.75em;
            position: absolute;
            border-bottom: 1px solid #EBEEF5;
          }

        }
      }
    }
  }
</style>