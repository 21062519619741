<template>
  <div class="column">
    <template v-if="scope.row.epcClaimed">
      <el-tag
          :type="scope.row.epcClaimed !== '1' ? 'danger' : 'success'"
          disable-transitions>
        {{ status }}
      </el-tag>
    </template>
  </div>
</template>
<script>
  export default {
    name: "AppointmentNotesEPCClaimStatusColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
    computed: {
      status() {
        if (this.scope.row.epcClaimed === "1") {
          return "Yes"
        } else {
          return "No"
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
