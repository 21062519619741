<template>
  <div class="form">
    <div
        v-if="searchingForPatient"
        v-loading="searchingForPatient"
        element-loading-text="Checking if Patient exists in Database ..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFF">
      <core-text />
    </div>
    <transition name="fade">
      <div v-if="patientsFound.length">
        <core-text class="hint">
          One ore more patients with this name have been found in our data base.
        </core-text>

        <ul class="found-patients">
          <li
              v-for="(patient, key) in patientsFound"
              :key="`patient-${key}`">
            <span @click="openPatientFile(patient.id)">
              {{ patient.firstName }} {{ patient.lastName }} | <strong>{{ patient.locationTitle }}</strong>
            </span>
            <a @click="createBooking(patient)">
              Create Booking for this patient
            </a>
          </li>
        </ul>
      </div>
    </transition>
    <CoreFormBuilderV2
        :data="patientData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onChange="onChange"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { Patient } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"
  import debounce from "lodash/debounce"

  export default {
    name: "PatientForm",
    props: {
      dialogProps: { type: Object, required: true },
      closeDialog: { type: Boolean, default: true },
      patientData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        searchingForPatient: false,
        patientsFound: [],
        formConfig,
        firstName: null,
        lastName: null
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      },
      fullName() {
        if (this.firstName && this.lastName) {
          return `${this.firstName} ${this.lastName}`
        }
        return null
      }
    },
    watch: {
      fullName() {
        this.debouncedPatientNameCheck()
      }
    },
    mounted() {
      this.debouncedPatientNameCheck = debounce(this.checkPatientName, 250)
    },
    methods: {
      createBooking(patient) {
        const type = "BookingDialog"
        const mode = "create"
        const optionsParams = {
          patientId: patient.id,
          locationId: patient.locationId
        }

        this.$store.dispatch("core/dialogStack/addDialog", { type, mode, optionsParams })
      },
      openPatientFile(id) {
        const type = "PatientDialog"
        const mode = "view"

        this.$store.dispatch("core/dialogStack/addDialog", { type, mode, id })

      },
      async checkPatientName() {
        if (this.firstName && this.lastName) {
          this.searchingForPatient = true

          const params = {
            filter: {
               patient: this.fullName
            },
            filterType: "exact"
          }
          const results = await Patient.api().fetchAll({params: params })

          const patients = results.response.data.data.data
          const count = results.response.data.data.meta.count

          if (count > 0) {
            this.patientsFound = patients
          } else {
            this.patientsFound = []
          }
          this.searchingForPatient = false
        }
      },
      onChange(Form) {
        if (this.mode === "create") {
          const flatFormData = Form.getFlatData()

          if (flatFormData.firstName && flatFormData.firstName.length >= 3) {
            this.firstName = flatFormData.firstName
          }
          if (flatFormData.lastName && flatFormData.lastName.length >= 3) {
            this.lastName = flatFormData.lastName
          }
        }
      },
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            let result = await Patient.api().create(flatFormData)
            this.$emit("nextStep", { status: "success", patientData: result.response.data.data.data })
          } else if (this.mode === "edit") {
            flatFormData.id = this.patientData.id
            await Patient.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your patient details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          if (this.closeDialog) {
            this.dialogProps.closeDialog()
          }
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hint {
    background: var(--main-light-yellow);
    font-style: normal !important;
    border-radius: 0.5em;
    color: rgba(0,0,0,0.4);
    width: 100%;
    padding: 0 0.5em;
  }

  .found-patients {
    margin: 0.5em 0;
    display: flex;
    li {
      span {
        text-transform: capitalize;
      }
      a {
        margin-left: 10px;
        background: var(--main-apple-blue);
        border-radius: 1em;
        padding: 0.05em 0.5em;
        color: white;
        font-size: 0.8em;
        cursor: pointer;
        transition: var(--main-transition);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

        &:hover {
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
</style>