import BookingDialog from "./BookingDialog/BookingDialog"
import PatientBookingDialog from "./BookingDialog/PatientBookingDialog"

export default [
  {
    name: "BookingDialog",
    Component: BookingDialog,
    permissions: "BookingManagement:Bookings:canCreate"
  },
  {
    name: "PatientBookingDialog",
    Component: PatientBookingDialog,
    permissions: "BookingManagement:Bookings:canCreate"
  }
]