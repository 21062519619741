<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="locationData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { Location } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"

  export default {
    name: "LocationForm",
    props: {
      dialogProps: { type: Object, required: true },
      locationData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            await Location.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.locationData.id
            await Location.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your location details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style scoped>

</style>