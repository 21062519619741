<template>
  <fragment>
    <CoreScreen
        card
        class="appointmentNotesManagement-wrapper"
        header="Ear Clinic Appointment Notes"
        icon="AppointmentNotesManagementIcon"
        :create-button="{ label: 'Create Appointment Note', onCreate: createAppointmentNotes }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { AppointmentNote } from "@/Modules/OdysseyModels"
export default {
  name: "AppointmentNotesManagement",
  methods: {
    createAppointmentNotes() {
      new AppointmentNote().Actions.openAppointmentNoteDialog()
    }
  }
}
</script>
