<template>
  <div class="list-notes-container">
    <el-empty
        v-if="notes.length <= 0"
        :description="`There are currently no notes for this ${type}.`" />
    <transition-group name="fade">
      <div
          v-for="(note, index) in notes"
          :key="`note-${index}`"
          class="note">
        <div class="delete-note-container">
          <el-popconfirm
              title="Are you sure to delete this?"
              confirm-button-text="Delete"
              @confirm="deleteNote(note.id)">
            <span
                slot="reference"
                class="el-icon-delete" />
          </el-popconfirm>
        </div>
        <time>{{ moment(note.createdAt).format("DD/MM/YYYY, h:mma") }}</time>
        <div class="note-container">
          <div class="note-title">
            {{ note.title }}
          </div>
          <div class="note-description">
            {{ note.description }}
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import { Note } from "@/Modules/OdysseyModels"
  import moment from "moment"

  export default {
    name: "ListNotes",
    props: {
      type: {
        type: String,
        required: true
      },
      typeId: {
        type: String,
        required: true
      },
      refresh: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        moment: moment,
        notes: [],
        isLoading: false
      }
    },
    watch: {
      refresh: {
        handler() {
          this.getNotes()
        }
      },
      typeId: {
        handler(val) {
          if (val) {
            this.getNotes()
          }
        },
        immediate: true
      }
    },
    methods: {
      async deleteNote(id) {
        await Note.api().destroy({ id: id})
        await this.getNotes()
      },
      async getNotes() {
        if (!this.loadedAppointmentNotes) {
          this.isLoading = true
          const response = await Note.api().fetchByType(this.type, this.typeId)
          this.notes = response.response.data.data.data
          this.isLoading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-notes-container {
    max-height: 500px;
    overflow-y: auto;
    .note {
      border-left: 2px solid var(--main-border-color);
      padding: 0 var(--padding-s) var(--padding-s) var(--padding-s);
      position: relative;


      &:nth-child(odd) {
        background: var(--main-light-grey);
      }

      time {
        font-size: 11px;
        display: block;
        right: 50px;
        top: 5px;
        position: absolute;
      }
      .delete-note-container {
        position: absolute;
        right: 15px;
        top: 5px;
        transition: var(--main-transition);
        cursor: pointer;

        &:hover {
          color: var(--main-red);
        }
      }

      .note-title {
        font-weight: 500;
      }

      margin-bottom: var(--margin-s);
    }
  }
</style>