<template>
  <div
      class="booking-details"
      ref="bookingDetailsContainer"
      :style="`height: ${height}px`"
      :class="{ 'week-view': weekView }">
    <template v-if="!isLoading">
      <el-popover
          ref="bookingDetailsPopover"
          placement="top-start"
          :title="`${booking.startTime} - ${booking.endTime} | ${booking.patientFirstName} ${booking.patientLastName} at ${booking.locationTitle}`"
          width="400"
          trigger="click">
        <div class="appointment-info-container">
          <el-tabs
              v-model="activeName">
            <el-tab-pane
                label="Appointment Details"
                name="first">
              <template v-if="booking.startTime || booking.endTime">
                <h3>Appointment Time</h3>
                <template v-if="booking.startTime">
                  {{ booking.startTime }}
                </template>
                <template v-if="booking.startTime && booking.endTime">
                  &ndash;
                </template>
                <template v-if="booking.endTime">
                  {{ booking.endTime }}
                </template>
              </template>

              <template v-if="booking.selectedUserId">
                <h3>Person in charge</h3>
                <span
                    class="tag"
                    :style="staffMemberColor">
                  {{ booking.userFirstName }} {{ booking.userLastName }}
                </span>
              </template>

              <template v-if="booking.locationId">
                <h3>Facility</h3>
                <strong>{{ booking.locationTitle }}</strong>
                <p>{{ booking.locationStreet }}, {{ booking.locationCity }} {{ booking.locationZip }}</p>
              </template>
              
              <template v-if="booking.notes">
                <h3>Notes</h3>
                {{ booking.notes }}
              </template>
            </el-tab-pane>
            <el-tab-pane
                v-if="appointmentNotes && appointmentNotes.length"
                label="Appointment Notes"
                name="second">
              <h3>Appointment Notes</h3>
              <ul class="appointment-notes">
                <li
                    v-for="(note, index) in appointmentNotes"
                    :key="`appointment-note-${index}`">
                  <div class="flex-container">
                    <div class="time">
                      {{ note.createdAt }}
                    </div>
                  </div>
                  <div class="appointment-note-view" />
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>

          <div class="options">
            <el-popconfirm
                icon="el-icon-delete"
                icon-color="red"
                :title="`Do you want to delete this appointment?`"
                confirm-button-text="OK"
                cancel-button-text="Cancel"
                @confirm="deleteClientBooking(booking)">
              <el-button
                  slot="reference"
                  size="small"
                  type="delete"
                  icon="el-icon-delete">
                Delete
              </el-button>
            </el-popconfirm>
            <el-button
                size="small"
                type="abel"
                icon="el-icon-edit"
                @click="editClientBooking(booking)">
              Edit
            </el-button>
            <template v-if="bookingNotesEnabled">
              <el-button
                  size="small"
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  @click="addAppointmentNotes(booking)">
                Appointment Notes
              </el-button>
            </template>
          </div>
        </div>
        <div
            slot="reference"
            class="appointment"
            :class="appointmentStatus"
            :style="`${bookingColor};min-height:${height}`">
          {{ booking.startTime }} - {{
            booking.endTime }} | {{ booking.patientFirstName }}
          {{ booking.patientLastName }}
          <br>
          at
          <strong>{{ booking.locationTitle }}</strong>
        </div>
      </el-popover>
    </template>
  </div>
</template>

<script>
  import {Booking, Settings, AppointmentNote} from "@/Modules/OdysseyModels"

  export default {
    name: "BookingDetails",
    props: {
      booking: {
        type: Object,
        required: true
      },
      weekView: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        appointmentNotes: null,
        patient: null,
        location: null,
        client: null,
        activeName: "first"
      }
    },
    computed: {
      isLoading: {
        get() {
          return !this.booking;
        },
        set(val) {
          return val
        }
      },
      hasContactDetails() {
        return !!(this.booking.client.phone || this.booking.client.email || this.booking.client.city);
      },
      appName() {
        return process.env.VUE_APP_BASE_PATH
      },
      bookingNotesEnabled() {
        // @todo: make this feature driven in the future
        return this.appName === 'Otocare'
      },
      settings() {
        return Settings.find(1)
      },
      staffMemberColor() {
        if (this.booking.userColor) {
          return `color: white; text-shadow: 1px 1px 0 rgba(0,0,0,0.1); background-color: ${this.booking.userColor}`
        }
        return null
      },
      bookingColor() {
        if (this.booking.selectedUserId > 0 && this.appointmentStatus === "active") {
          return `color: white; text-shadow: 1px 1px 0 rgba(0,0,0,0.1); background-color: ${this.booking.userColor}`
        }
        return null
      },
      height() {
          const { booking } = this
          const date = new Date()
          const compareDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
          const startTime = new Date(`${compareDate} ${booking.startTime}`).getTime()
          const endTime = new Date(`${compareDate} ${booking.endTime}`).getTime()
          let timeDiff = endTime - startTime
          timeDiff = timeDiff / 60 / 1000

          return timeDiff
      },
      appointmentStatus() {
        let bookingDate = this.convertDateForIOS(this.booking.date)
        bookingDate = bookingDate.setHours(0, 0, 0, 0)
        if (bookingDate >= Date.parse(this.currentDate)) {
          return "active"
        }
        return "inactive"
      },
      currentDate() {
        let date = new Date()
        // return new Date()
        return new Date(date.setDate(date.getDate() - 1))
      }
    },
    mounted() {
      if (this.appName === "Otocare") {
        this.getAppointmentNotes()
      }
    },
    methods: {
      editAppointmentNotes(note) {
        this.$emit("editAppointmentNotes", note)
      },
      async getAppointmentNotes() {
        if (!this.appointmentNotes) {
          this.isLoading = true
          const response = await AppointmentNote.api().fetchByPatientId(this.booking.patientId)
          this.isLoading = false
          if (response.response.data.data) {
            this.appointmentNotes = response.response.data.data.data
          }
          // Filter out all notes that don't belong to this booking
          this.appointmentNotes.filter(item =>
              item.bookingId === this.booking.id)
        }
      },
      convertDateForIOS(date) {
        const arr = date.split(/[- :]/)
        return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5])
      },
      async deleteClientBooking(row) {
        await Booking.api().destroy({id: row.id})
        Booking.delete(row.id)
        // Simulate click to close popover
        this.$refs.bookingDetailsContainer.click()
        this.$emit('refreshBookings')
      },
      editClientBooking(row) {
        this.$emit("editClientBooking", row)
      },
      addAppointmentNotes(row) {
        new AppointmentNote().Actions.openAppointmentNoteDialog({ bookingData: JSON.parse(JSON.stringify(row)) })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .week-view {
    .appointment {
      z-index: 10;
      left: 0;
      opacity: 0.8;
      width: 100%;
    }
  }
  .appointment {
    width: 90%;
    line-height: 1.6;
    padding: 0.2em 1em;
    border-radius: 0.75em;
    display: inline-block;
    margin: 0.2em;
    box-shadow: var(--box-shadow-container-light);
    color: var(--main-primary);
    transition: var(--main-transition);
    background: var(--main-light-grey);
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 10;

    &.active {
      background: var(--main-secondary);
      color: var(--main-white);
    }

    &:hover {
      background: var(--main-primary);
      color: var(--main-white);
      opacity: 1;
      border-color: white;
      z-index: 12;
    }
  }

  .appointment-info-container {
    padding: 1em;

    h3 {
      font-weight: 500;
      font-size: 0.9em;
      margin: 2em 0 0.5em 0;
      color: var(--main-primary);

      &:first-child {
        margin-top: 0;
      }
    }

    dl {
      display: flex;

      dt {
        font-weight: 500;
        width: 4em;
      }
    }

    .tag {
      display: inline-block;
      color: white;
      padding: 0.25em 1em;
      border-radius: 0.25em;
    }

    .options {
      margin: 4em -1em 0 -1em;
      text-align: right;
      display: flex;
      justify-content: space-between;

      button {
        margin:0 1em;
      }
    }

    ul.appointment-notes {
      li {
        align-items: center;
        background: var(--main-light-grey);
        .flex-container {
          display: flex;
          flex-wrap: wrap;
          padding: 0.5em 1em;
          justify-content: space-between;

          div {
            flex: 1;

            &.actions {
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .appointment-note-view {
          font-size: 0.8em;

          ::v-deep  .action-buttons {
            text-align: right;
            margin-top: -2.5em;
          }
        }
      }
    }
  }

  ::v-deep  .el-alert__icon {
    font-size: 1em !important;
  }
</style>