import { render, staticRenderFns } from "./PatientStatusColumn.vue?vue&type=template&id=2d120fbc&scoped=true&"
import script from "./PatientStatusColumn.vue?vue&type=script&lang=js&"
export * from "./PatientStatusColumn.vue?vue&type=script&lang=js&"
import style0 from "./PatientStatusColumn.vue?vue&type=style&index=0&id=2d120fbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d120fbc",
  null
  
)

export default component.exports