<template>
  <fragment>
    <CoreScreen
        card
        class="bookingManagement-wrapper"
        header="Manage Bookings"
        icon="BookingManagementIcon"
        :create-button="createButtons">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Booking } from "@/Modules/OdysseyModels"
export default {
  name: "BookingManagement",
  computed: {
    createButtons() {
      return [
        { label: 'Create Patient & Book', onCreate: this.createPatientAndBooking },
        { label: 'Create Booking', onCreate: this.createBooking },
      ]
    }
  },
  methods: {
    createBooking() {
      new Booking().Actions.openBookingDialog()
    },
    createPatientAndBooking() {
      new Booking().Actions.openPatientBookingDialog()
    }
  }
}
</script>
