<template>
  <CoreDialog
      title="Patient Importer"
      :dialog-props="dialogProps"
      width="70%">
    <template slot="content">
      <CoreDataImporter
          entity-name="Patients"
          :mapping-to-fields="mappingToFields"
          :successfully-imported-rows="successfullyImportedRows"
          :default-field-selectors="defaultFieldSelectors"
          @resetSuccessfullyImportedRows="resetSuccessfullyImportedRows"
          @submitImport="submitData" />
    </template>
  </CoreDialog>
</template>

<script>
  import {Patient, Location, GeneralPractitioner } from "@/Modules/OdysseyModels"
  
  export default {
    name: "ImportPatientsDialog",
    filters: {
      capitalise(string) {
        return startCase(string)
      }
    },
    components: {
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        showSuccessMessage: false,
        successfullyImportedRows: 0,
        mappingToFields: [
          "_ignore_",
          "firstName",
          "lastName",
          "dob",
          "medicareNumber",
          "medicareReferenceNumber",
          "dvaNumber",
          "pensionCardNumber",
          "referringGPId",
          "locationId",
          "deceased",
          "consent",
          "referralDate",
          "numberOfVisits",
          "epcClaimed",
          "notes"
        ],
        defaultFieldSelectors: {
          locationId: {
            label: "Facility",
            options: {}
          },
          referringGPId: {
            label: "General Practitioner",
            options: {}
          }
        }
      }
    },
    computed: {
      showLocationSelector() {
        const foundLocationId = Object.keys(this.mapValues).find(key => this.mapValues[key] === "locationId")
        return !foundLocationId
      },
      showGPSelector() {
        const foundGPId = Object.keys(this.mapValues).find(key => this.mapValues[key] === "referringGPId")
        return !foundGPId
      },
      locations() {
        return Location.all().map(location => location.valueList)
      },
      gps() {
        return GeneralPractitioner.all().map(gp => gp.valueList)
      },
    },
    watch: {
      locations() {
        this.defaultFieldSelectors.locationId.options = this.locations
      },
      gps() {
        this.defaultFieldSelectors.referringGPId.options = this.gps
      }
    },
    beforeMount() {
      Location.api().fetchAll()
      GeneralPractitioner.api().fetchAll()
    },
    methods: {
      resetSuccessfullyImportedRows() {
        this.successfullyImportedRows = 0
      },
      async submitData(data) {
        Patient.api()
        const importResponse = await Patient.api().importPatients(data)

        if (importResponse.response.data.success) {
          this.successfullyImportedRows = importResponse.response.data.meta.total
        }
        this.uploadData = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hint {
    color: rgba(0, 0, 0, 0.7) !important;
    margin: 1em 0;
    padding: 0.5em 1em;

    &.yellow {
      background: var(--main-light-yellow);
      border-radius: var(--border-radius-xs)

    }
  }
  .import-data-button-wrapper {
    margin: 2em;
    text-align: right;
  }
  .extra-import-selectors {
    display: flex;
    .gp-selector-wrapper,
    .location-selector-wrapper {
      margin: 2em;

      label {
        margin-right: 1em;
      }
    }
  }

  .response-wrapper {
    margin: 2em 0;

    .hint-yellow {
      background: var(--main-light-yellow);
      border-radius: 1em;
      padding: 0.5em 1em;
    }
  }


</style>