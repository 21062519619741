<template>
  <fragment>
    <CoreScreen
        card
        class="patient-management-wrapper"
        header="Patient Management"
        icon="PatientManagementIcon"
        :create-button="createButtons">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Patient } from "@/Modules/OdysseyModels"
export default {
  name: "PatientManagement",
  computed: {
    createButtons() {
      return [
        { label: 'Create Patient', onCreate: this.createPatient },
        { label: 'Import Patients', onCreate: this.importPatients }
      ]
    }
  },
  methods: {
    createPatient() {
      new Patient().Actions.openPatientDialog()
    },
    importPatients() {
      new Patient().Actions.openImportPatientsDialog()
    }
  },
}
</script>
