import routes, { apiActions } from  "./routes"

const attributes = {
    id: 1,
    userId: null,
    createdAt: null,
    bookingId: null,
    locationId: null,
    patientId: null,
    otoscopyLeft: null,
    otoscopyRight: null,
    hearingScreenLeft: null,
    hearingScreenRight: null,
    middleEarAssessmentLeft: null,
    middleEarAssessmentRight: null,
    actionTakenLeft: [],
    actionTakenRight: [],
    recommendation: null,
    extraNotes: null,
    patientFirstName: null,
    patientLastName: null,
    patientDob: null,
    patientMedicareNumber: null,
    patientMedicareReferenceNumber: null,
    gpMedicareProviderNumber: null,
    gpFirstName: null,
    gpLastName: null,
    staffFirstName: null,
    staffLastName: null,
    locationName: null,
    recommendTVHeadPhones: false,
    recommendALD: false,
    pdfLocation: null,
    epcClaimLocation: null,
    epcClaimed: 0,
    bilatACGreaterThanBC: null,
    midline: null,
    clBcAcInL: null,
    clBcAcInR: null,
    clAcBcInL: null,
    clAcBcInR: null,
    clLateralizeToL: null,
    clLateralizeToR: null,
    shLateralizeToL: null,
    shLateralizeToR: null,
    shLateralizeAwayFromL: null,
    shLateralizeAwayFromR: null,
    mhlBcAcInL: null,
    mhlBcAcInR: null,
    mhlAcBcInL: null,
    mhlAcBcInR: null,
    mhlLateralizeToL: null,
    mhlLateralizeToR: null,
    mhlLateralizeAwayFromL: null,
    mhlLateralizeAwayFromR: null,
    cerumenolyticsAdministeredLeft: null,
    cerumenolyticsAdministeredRight: null,
    otoscopyImageLeft: null,
    otoscopyImageRight: null,
    bcHearingScreenLeft: null,
    bcHearingScreenRight: null,
    airBoneGapLeft: null,
    airBoneGapRight: null,
    ptaAudiogramImage: null,
    speechAudiogramImage: null,
    acousticReflexesPresentLeft: null,
    acousticReflexesPresentRight: null,
    medicareItems: null,
    weberRinneTestTable: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition