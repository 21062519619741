<template>
  <div class="locations-table-view">
    <core-data-table-v2
        :model="locationModel"
        :columns="columns"
        table-name="location-management"
        v-on="$listeners" />
  </div>
</template>

<script>
  import { Location } from "@/Modules/OdysseyModels"
  import columns from "../components/LocationsManagementTableView/columns"
  export default {
    name: "LocationsTableView",
    computed: {
      locationModel() {
        return Location
      },
      columns() {
        return columns
      }
    }
  }
</script>
