<template>
  <div id="main-wrapper">
    <transition
        v-if="regionsLoaded"
        name="fade">
      <CoreLayoutSideNav />
    </transition>

    <core-loader v-else />
  </div>
</template>

<script>
import { Client } from "@/Modules/OdysseyModels"

export default {
  data() {
    return {
      regionsLoaded: false,
      env: process.env.NODE_ENV
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.loggedIn
    }
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler() {
        if (this.isAuthenticated) {
          this.fetchClients()
        }
      }
    }
  },
  mounted() {
    if (this.env === "development" || this.env === "staging") {
      if (localStorage.getItem("useMockData") !== null) {
        this.$store.dispatch("settings/updateUseMockData", true)
      }
    }
  },
  methods: {
    async fetchClients() {
      await Client.api().fetchAll()

      this.regionsLoaded = true
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;700');
#app,
#root,
#main-wrapper,
iframe,
body {
  font-family: "Baloo Paaji 2", Helvetica, Arial, sans-serif !important;

  // App specific Branding settings
  --primary-font: "Baloo Paaji 2", sans-serif !important;
  // secondary color: 341, 86%, 54%

  /*--brand-hue: 271 !important;*/
  /*--brand-sat: 86% !important;*/
  /*--brand-light: 54% !important;*/

  /*!* Primary Color *!*/
  /*--primary-hue: 216 !important;*/
  /*--primary-sat: 25% !important;*/
  /*--primary-light: 45% !important;*/

  /*!* Secondary Color *!*/
  /*--secondary-hue: 341 !important;*/
  /*--secondary-sat: 86% !important;*/
  /*--secondary-light: 54% !important;*/
}
</style>
