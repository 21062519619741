const state = () => {
  return {
    currentDate: new Date(),
    reloadBookings: false
  }
}

const actions = {
  updateCurrentDate({ commit }, payload) {
    commit("UPDATE_CURRENT_DATE", payload)
  },
  updateReloadBookings({ commit }, payload) {
    commit("UPDATE_RELOAD_BOOKINGS", payload)

    setTimeout(() => {
      commit("UPDATE_RELOAD_BOOKINGS", false)
    }, 400)
  }
}

const mutations = {
  UPDATE_CURRENT_DATE(state, payload) {
    state.currentDate = payload
  },
  UPDATE_RELOAD_BOOKINGS(state, payload) {
    state.reloadBookings = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
