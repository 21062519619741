<template>
  <div>
    <core-data-table-v2
        :model="patientModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="patient-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Patient } from "@/Modules/OdysseyModels"
import columns from "../components/PatientManagementTableView/columns"
import filterConfig from "../components/PatientManagementTableView/filterConfig"

export default {
  name: "PatientTableView",
  data() {
    return {
      filters: filterConfig
    }
  },
  computed: {
    patientModel() {
      return Patient
    },
    columns() {
      return columns
    }
  }
}
</script>
