<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <BookingManagementForm
          :dialog-props="dialogProps"
          :booking-data="bookingData" />
    </template>
  </CoreDialog>
</template>

<script>
  import BookingManagementForm from "../../forms/BookingManagementForm/BookingManagementForm"
  import { Booking } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "BookingManagementDialog",
    components: {
      BookingManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      bookingData() {
        return Booking.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.bookingData) {
          return `${startCase(this.mode)}ing BookingManagement ${this.bookingData.fullName} (ID: ${this.id})`
        }

        return "Create New BookingManagement"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchBooking()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchBooking() {
        this.isLoading = true

        await Booking.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>