<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      :tabs="tabs"
      mode-switcher-enabled>
    <template slot="content">
      <LocationForm
          :dialog-props="dialogProps"
          :location-data="location" />
    </template>
  </CoreDialog>
</template>

<script>
  import LocationForm from "../../forms/LocationForm/LocationForm"
  import LocationNotes from "@/Modules/LocationsManagement/dialogs/LocationDialog/LocationNotes";
  import { Location } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "LocationDialog",
    components: {
      LocationForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
      locationData: { type: Object, required: true }
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      tabs() {
        const props = { locationId: this.id, locationData: this.location }

        return [
          { label: "Location Details", Component: LocationForm, props, modeSwitcherEnabled: true},
          { label: "Notes", Component: LocationNotes, props},
        ]
      },
      location() {
        return Location.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.location) {
          return `${startCase(this.mode)}ing Location ${this.location.title} (ID: ${this.id})`
        }

        return "Create New Location"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchLocation()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchLocation() {
        this.isLoading = true

        await Location.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>